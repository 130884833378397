exports.hobbies = [
  { value: 'reading', label: 'Reading' },
  { value: 'traveling', label: 'Traveling' },
  { value: 'cooking', label: 'Cooking' },
  { value: 'sports', label: 'Sports' },
  { value: 'music', label: 'Music' },
  { value: 'gardening', label: 'Gardening' },
  { value: 'photography', label: 'Photography' },
  { value: 'painting', label: 'Painting' },
  { value: 'writing', label: 'Writing' },
  { value: 'dancing', label: 'Dancing' },
  { value: 'hiking', label: 'Hiking' },
  { value: 'fishing', label: 'Fishing' },
  { value: 'knitting', label: 'Knitting' },
  { value: 'yoga', label: 'Yoga' },
  { value: 'baking', label: 'Baking' },
  { value: 'drawing', label: 'Drawing' },
  { value: 'running', label: 'Running' },
  { value: 'cycling', label: 'Cycling' },
  { value: 'swimming', label: 'Swimming' },
  { value: 'birdwatching', label: 'Birdwatching' },
  { value: 'camping', label: 'Camping' },
  { value: 'volunteering', label: 'Volunteering' },
  { value: 'board games', label: 'Board Games' },
  { value: 'puzzles', label: 'Puzzles' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'collecting', label: 'Collecting' },
  { value: 'origami', label: 'Origami' },
  { value: 'model building', label: 'Model Building' },
  { value: 'scrapbooking', label: 'Scrapbooking' },
  { value: 'calligraphy', label: 'Calligraphy' },
];

exports.interests = [
  { value: 'technology', label: 'Technology' },
  { value: 'science', label: 'Science' },
  { value: 'arts', label: 'Arts' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'travel', label: 'Travel' },
  { value: 'food', label: 'Food' },
  { value: 'movies', label: 'Movies' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'literature', label: 'Literature' },
  { value: 'history', label: 'History' },
  { value: 'politics', label: 'Politics' },
  { value: 'photography', label: 'Photography' },
  { value: 'architecture', label: 'Architecture' },
  { value: 'design', label: 'Design' },
  { value: 'finance', label: 'Finance' },
  { value: 'philosophy', label: 'Philosophy' },
  { value: 'education', label: 'Education' },
  { value: 'environment', label: 'Environment' },
  { value: 'health', label: 'Health' },
  { value: 'entrepreneurship', label: 'Entrepreneurship' },
  { value: 'psychology', label: 'Psychology' },
  { value: 'spirituality', label: 'Spirituality' },
  { value: 'DIY', label: 'DIY' },
  { value: 'martial arts', label: 'Martial Arts' },
  { value: 'music', label: 'Music' },
  { value: 'theater', label: 'Theater' },
  { value: 'pets', label: 'Pets' },
  { value: 'science fiction', label: 'Science Fiction' },
  { value: 'anime', label: 'Anime' },
];

exports.qualifications = [
  { value: 'high_school', label: 'High School Diploma' },
  { value: 'associate', label: 'Associate Degree' },
  { value: 'bachelor', label: 'Bachelor\'s Degree' },
  { value: 'master', label: 'Master\'s Degree' },
  { value: 'doctorate', label: 'Doctorate (Ph.D.)' },
  { value: 'professional', label: 'Professional Degree (MD, JD, etc.)' },
  { value: 'cert_cs', label: 'Certificate in Computer Science' },
  { value: 'cert_ds', label: 'Certificate in Data Science' },
  { value: 'diploma_eng', label: 'Diploma in Engineering' },
  { value: 'diploma_ba', label: 'Diploma in Business Administration' },
  { value: 'diploma_gd', label: 'Diploma in Graphic Design' },
  { value: 'diploma_marketing', label: 'Diploma in Marketing' },
  { value: 'diploma_nursing', label: 'Diploma in Nursing' },
  { value: 'diploma_education', label: 'Diploma in Education' },
  { value: 'cert_pm', label: 'Certificate in Project Management' },
  { value: 'cert_hr', label: 'Certificate in Human Resources' },
  { value: 'cert_fp', label: 'Certificate in Financial Planning' },
  { value: 'cert_wd', label: 'Certificate in Web Development' },
  { value: 'cert_culinary', label: 'Certificate in Culinary Arts' },
  { value: 'cert_dm', label: 'Certificate in Digital Marketing' },
  { value: 'cert_hs', label: 'Certificate in Health and Safety' },
  { value: 'diploma_psych', label: 'Diploma in Psychology' },
  { value: 'diploma_env_sc', label: 'Diploma in Environmental Science' },
  { value: 'diploma_journalism', label: 'Diploma in Journalism' },
  { value: 'diploma_sw', label: 'Diploma in Social Work' },
  { value: 'cert_cyber', label: 'Certificate in Cybersecurity' },
  { value: 'cert_ai', label: 'Certificate in Artificial Intelligence' },
  { value: 'diploma_fine_arts', label: 'Diploma in Fine Arts' },
  { value: 'cert_na', label: 'Certificate in Network Administration' },
  { value: 'cert_fitness', label: 'Certificate in Fitness Training' },
];

exports.locations = [
  
  // Places in Kerala
  { label: "Thiruvananthapuram, Kerala", value: "Thiruvananthapuram" },
  { label: "Kochi, Kerala", value: "Kochi" },
  { label: "Kozhikode, Kerala", value: "Kozhikode" },
  { label: "Thrissur, Kerala", value: "Thrissur" },
  { label: "Kannur, Kerala", value: "Kannur" },
  { label: "Kollam, Kerala", value: "Kollam" },
  { label: "Alappuzha, Kerala", value: "Alappuzha" },
  { label: "Palakkad, Kerala", value: "Palakkad" },
  { label: "Malappuram, Kerala", value: "Malappuram" },
  { label: "Kottayam, Kerala", value: "Kottayam" },
  { label: "Pathanamthitta, Kerala", value: "Pathanamthitta" },
  { label: "Idukki, Kerala", value: "Idukki" },
  { label: "Wayanad, Kerala", value: "Wayanad" },
  { label: "Nellore, Kerala", value: "Nellore" },
  { label: "Ponnani, Kerala", value: "Ponnani" },
  { label: "Varkala, Kerala", value: "Varkala" },
  { label: "Changanassery, Kerala", value: "Changanassery" },
  { label: "Kattappana, Kerala", value: "Kattappana" },
  { label: "Cherthala, Kerala", value: "Cherthala" },
  { label: "Muvattupuzha, Kerala", value: "Muvattupuzha" },
  { label: "Kalamassery, Kerala", value: "Kalamassery" },
  { label: "Kothamangalam, Kerala", value: "Kothamangalam" },
  { label: "Palluruthy, Kerala", value: "Palluruthy" },
  { label: "Chirakkal, Kerala", value: "Chirakkal" },
  { label: "Sreekariyam, Kerala", value: "Sreekariyam" },
  { label: "Edappal, Kerala", value: "Edappal" },
  { label: "Irinjalakuda, Kerala", value: "Irinjalakuda" },
  { label: "Payyannur, Kerala", value: "Payyannur" },
  { label: "Angamaly, Kerala", value: "Angamaly" },
  { label: "Kodungallur, Kerala", value: "Kodungallur" },
  { label: "Mattannur, Kerala", value: "Mattannur" },
  { label: "Bharananganam, Kerala", value: "Bharananganam" },
  { label: "Pulpally, Kerala", value: "Pulpally" },
  { label: "Kozhencherry, Kerala", value: "Kozhencherry" },
  { label: "Kunnamkulam, Kerala", value: "Kunnamkulam" },
  { label: "Mavelikkara, Kerala", value: "Mavelikkara" },
  { label: "Punalur, Kerala", value: "Punalur" },

  // Major cities in India
  { label: "Mumbai, Maharashtra", value: "Mumbai" },
  { label: "Delhi", value: "Delhi" },
  { label: "Bangalore, Karnataka", value: "Bangalore" },
  { label: "Hyderabad, Telangana", value: "Hyderabad" },
  { label: "Ahmedabad, Gujarat", value: "Ahmedabad" },
  { label: "Chennai, Tamil Nadu", value: "Chennai" },
  { label: "Kolkata, West Bengal", value: "Kolkata" },
  { label: "Surat, Gujarat", value: "Surat" },
  { label: "Pune, Maharashtra", value: "Pune" },
  { label: "Jaipur, Rajasthan", value: "Jaipur" },
  { label: "Lucknow, Uttar Pradesh", value: "Lucknow" },
  { label: "Kanpur, Uttar Pradesh", value: "Kanpur" },
  { label: "Nagpur, Maharashtra", value: "Nagpur" },
  { label: "Indore, Madhya Pradesh", value: "Indore" },
  { label: "Thane, Maharashtra", value: "Thane" },
  { label: "Bhopal, Madhya Pradesh", value: "Bhopal" },
  { label: "Visakhapatnam, Andhra Pradesh", value: "Visakhapatnam" },
  { label: "Pimpri-Chinchwad, Maharashtra", value: "Pimpri-Chinchwad" },
  { label: "Patna, Bihar", value: "Patna" },
  { label: "Vadodara, Gujarat", value: "Vadodara" },
  { label: "Ghaziabad, Uttar Pradesh", value: "Ghaziabad" },
  { label: "Ludhiana, Punjab", value: "Ludhiana" },
  { label: "Agra, Uttar Pradesh", value: "Agra" },
  { label: "Nashik, Maharashtra", value: "Nashik" },
  { label: "Faridabad, Haryana", value: "Faridabad" },
  { label: "Meerut, Uttar Pradesh", value: "Meerut" },
  { label: "Rajkot, Gujarat", value: "Rajkot" },
  { label: "Kalyan-Dombivli, Maharashtra", value: "Kalyan-Dombivli" },
  { label: "Vijayawada, Andhra Pradesh", value: "Vijayawada" },
  { label: "Srinagar, Jammu and Kashmir", value: "Srinagar" },
  { label: "Jabalpur, Madhya Pradesh", value: "Jabalpur" },
  { label: "Mysore, Karnataka", value: "Mysore" },
  { label: "Coimbatore, Tamil Nadu", value: "Coimbatore" },
  { label: "Dehradun, Uttarakhand", value: "Dehradun" },
  { label: "Ranchi, Jharkhand", value: "Ranchi" },
  { label: "Jodhpur, Rajasthan", value: "Jodhpur" },
  { label: "Gwalior, Madhya Pradesh", value: "Gwalior" },
  { label: "Bhubaneswar, Odisha", value: "Bhubaneswar" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Kakinada, Andhra Pradesh", value: "Kakinada" },
  { label: "Shimla, Himachal Pradesh", value: "Shimla" },
  { label: "Durgapur, West Bengal", value: "Durgapur" },
  { label: "Tiruchirappalli, Tamil Nadu", value: "Tiruchirappalli" },

  // Famous global places
  { label: "New York, USA", value: "New York" },
  { label: "London, UK", value: "London" },
  { label: "Paris, France", value: "Paris" },
  { label: "Tokyo, Japan", value: "Tokyo" },
  { label: "Sydney, Australia", value: "Sydney" },
  { label: "Dubai, UAE", value: "Dubai" },
  { label: "Singapore", value: "Singapore" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Toronto, Canada", value: "Toronto" },
  { label: "Los Angeles, USA", value: "Los Angeles" },
  { label: "Rome, Italy", value: "Rome" },
  { label: "Barcelona, Spain", value: "Barcelona" },
  { label: "Berlin, Germany", value: "Berlin" },
  { label: "Amsterdam, Netherlands", value: "Amsterdam" },
  { label: "Vienna, Austria", value: "Vienna" },
  { label: "Lisbon, Portugal", value: "Lisbon" },
  { label: "Istanbul, Turkey", value: "Istanbul" },
  { label: "Dubai, UAE", value: "Dubai" },
  { label: "Seoul, South Korea", value: "Seoul" },
  { label: "Moscow, Russia", value: "Moscow" },
  { label: "Cairo, Egypt", value: "Cairo" },
  { label: "Bangkok, Thailand", value: "Bangkok" },
  { label: "San Francisco, USA", value: "San Francisco" },
  { label: "Chicago, USA", value: "Chicago" },
  { label: "Dubai, UAE", value: "Dubai" },
  { label: "Mexico City, Mexico", value: "Mexico City" },
  { label: "Buenos Aires, Argentina", value: "Buenos Aires" },
  { label: "Rio de Janeiro, Brazil", value: "Rio de Janeiro" },
  { label: "Sao Paulo, Brazil", value: "Sao Paulo" },
  { label: "Johannesburg, South Africa", value: "Johannesburg" },
  { label: "Lima, Peru", value: "Lima" },
  { label: "Helsinki, Finland", value: "Helsinki" },
  { label: "Stockholm, Sweden", value: "Stockholm" },
  { label: "Oslo, Norway", value: "Oslo" },
  { label: "Reykjavik, Iceland", value: "Reykjavik" },
  { label: "Dubai, UAE", value: "Dubai" },
  { label: "Quito, Ecuador", value: "Quito" },
  { label: "Panama City, Panama", value: "Panama City" },
  { label: "Havana, Cuba", value: "Havana" },
  { label: "Bucharest, Romania", value: "Bucharest" },
  { label: "Warsaw, Poland", value: "Warsaw" },
  { label: "Athens, Greece", value: "Athens" },
  { label: "Prague, Czech Republic", value: "Prague" },
  { label: "Budapest, Hungary", value: "Budapest" },
  { label: "Kiev, Ukraine", value: "Kiev" },
  { label: "Tallinn, Estonia", value: "Tallinn" },
  { label: "Riga, Latvia", value: "Riga" },
  { label: "Vilnius, Lithuania", value: "Vilnius" },
];

exports.gender = [
  { label: "Male", value: "male" },
  { label: "Famale", value: "female" },
  { label: "Others", value: "other" },
];

exports.smokingHabits = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Planning to quit", value: "planningtoquit" },
];

exports.drinkingHabits = [
  { label: "Regular", value: "regular" },
  { label: "Planning To Quit", value: "planningToQuit" },
  { label: "Socially", value: "socially" },
  { label: "Occasionally", value: "occasionally" },
  { label: "Teetotaler", value: "teeToTaler" },
];

exports.expertLevel = [
  { label: "Beginner", value: "beginner" },
  { label: "Intern", value: "intern" },
  { label: "Expert", value: "expert" },
];

exports.lifestyleChoices = [
  { value: 'active', label: 'Active Lifestyle' },
  { value: 'sedentary', label: 'Sedentary Lifestyle' },
  { value: 'healthy_eating', label: 'Healthy Eating' },
  { value: 'balanced_diet', label: 'Balanced Diet' },
  { value: 'fitness_enthusiast', label: 'Fitness Enthusiast' },
  { value: 'mindfulness', label: 'Mindfulness Practice' },
  { value: 'regular_exercise', label: 'Regular Exercise' },
  { value: 'social_engagement', label: 'Social Engagement' },
  { value: 'work_life_balance', label: 'Work-Life Balance' },
  { value: 'minimalist', label: 'Minimalist Living' },
  { value: 'sustainable', label: 'Sustainable Living' },
  { value: 'eco_friendly', label: 'Eco-Friendly Practices' },
  { value: 'frequent_traveler', label: 'Frequent Traveler' },
  { value: 'family_oriented', label: 'Family-Oriented' },
  { value: 'health_conscious', label: 'Health Conscious' },
  { value: 'wellness_focused', label: 'Wellness Focused' },
  { value: 'hobbyist', label: 'Hobbyist' },
  { value: 'diy_enthusiast', label: 'DIY Enthusiast' },
  { value: 'career_focused', label: 'Career Focused' },
  { value: 'homebody', label: 'Homebody' },
  { value: 'pet_owner', label: 'Pet Owner' },
  { value: 'community_involved', label: 'Community Involved' },
  { value: 'cultural_interest', label: 'Cultural Interest' },
  { value: 'self_care_advocate', label: 'Self-Care Advocate' },
  { value: 'volunteer_work', label: 'Volunteer Work' },
  { value: 'tech_savvy', label: 'Tech-Savvy' },
  { value: 'financially_savvy', label: 'Financially Savvy' },
  { value: 'education_oriented', label: 'Education-Oriented' },
  { value: 'creative_pursuits', label: 'Creative Pursuits' },
  { value: 'spiritual_practice', label: 'Spiritual Practice' },
];

exports.religions = [
  { value: 'christianity', label: 'Christianity' },
  { value: 'islam', label: 'Islam' },
  { value: 'hinduism', label: 'Hinduism' },
  { value: 'buddhism', label: 'Buddhism' },
  { value: 'sikhism', label: 'Sikhism' },
  { value: 'jainism', label: 'Jainism' },
  { value: 'judaism', label: 'Judaism' },
  { value: 'bahaism', label: 'Baháʼí Faith' },
  { value: 'zoroastrianism', label: 'Zoroastrianism' },
  { value: 'shinto', label: 'Shinto' },
  { value: 'taoism', label: 'Taoism' },
  { value: 'confucianism', label: 'Confucianism' },
  { value: 'unitarian_universalism', label: 'Unitarian Universalism' },
  { value: 'new_age', label: 'New Age' },
  { value: 'atheism', label: 'Atheism' },
  { value: 'agnosticism', label: 'Agnosticism' },
  { value: 'paganism', label: 'Paganism' },
  { value: 'wicca', label: 'Wicca' },
  { value: 'druidry', label: 'Druidry' },
  { value: 'spiritual_but_not_religious', label: 'Spiritual but Not Religious' },
  { value: 'native_american_religions', label: 'Native American Religions' },
  { value: 'aboriginal_spirituality', label: 'Aboriginal Spirituality' },
  { value: 'ancient_egyptian_religion', label: 'Ancient Egyptian Religion' },
  { value: 'ancient_greek_religion', label: 'Ancient Greek Religion' },
  { value: 'ancient_roman_religion', label: 'Ancient Roman Religion' },
  { value: 'zoroastrian', label: 'Zoroastrian' },
  { value: 'sufism', label: 'Sufism' },
  { value: 'mysticism', label: 'Mysticism' },
  { value: 'humanism', label: 'Humanism' },
  { value: 'secular_humanism', label: 'Secular Humanism' },
  { value: 'church_of_scientology', label: 'Church of Scientology' },
  { value: 'raelian_movement', label: 'Raelian Movement' },
];

exports.occupations = [
  { value: 'software_engineer', label: 'Software Engineer' },
  { value: 'doctor', label: 'Doctor' },
  { value: 'nurse', label: 'Nurse' },
  { value: 'teacher', label: 'Teacher' },
  { value: 'accountant', label: 'Accountant' },
  { value: 'lawyer', label: 'Lawyer' },
  { value: 'engineer', label: 'Engineer' },
  { value: 'graphic_designer', label: 'Graphic Designer' },
  { value: 'marketing_manager', label: 'Marketing Manager' },
  { value: 'sales_representative', label: 'Sales Representative' },
  { value: 'architect', label: 'Architect' },
  { value: 'chef', label: 'Chef' },
  { value: 'photographer', label: 'Photographer' },
  { value: 'writer', label: 'Writer' },
  { value: 'scientist', label: 'Scientist' },
  { value: 'journalist', label: 'Journalist' },
  { value: 'pharmacist', label: 'Pharmacist' },
  { value: 'electrician', label: 'Electrician' },
  { value: 'plumber', label: 'Plumber' },
  { value: 'real_estate_agent', label: 'Real Estate Agent' },
  { value: 'fitness_trainer', label: 'Fitness Trainer' },
  { value: 'web_developer', label: 'Web Developer' },
  { value: 'project_manager', label: 'Project Manager' },
  { value: 'social_worker', label: 'Social Worker' },
  { value: 'financial_analyst', label: 'Financial Analyst' },
  { value: 'consultant', label: 'Consultant' },
  { value: 'dentist', label: 'Dentist' },
  { value: 'veterinarian', label: 'Veterinarian' },
  { value: 'civil_servant', label: 'Civil Servant' },
  { value: 'event_planner', label: 'Event Planner' },
  { value: 'fashion_designer', label: 'Fashion Designer' },
  { value: 'musician', label: 'Musician' },
];

exports.countryCodes = [
  { label: '+91', value: 'India' },
  { label: '+1', value: 'United States' },
  { label: '+44', value: 'United Kingdom' },
  { label: '+81', value: 'Japan' },
  { label: '+86', value: 'China' },
  { label: '+49', value: 'Germany' },
  { label: '+33', value: 'France' },
  { label: '+61', value: 'Australia' },
  { label: '+55', value: 'Brazil' },
  { label: '+7', value: 'Russia' },
  { label: '+27', value: 'South Africa' },
  { label: '+34', value: 'Spain' },
  { label: '+39', value: 'Italy' },
  { label: '+52', value: 'Mexico' },
  { label: '+82', value: 'South Korea' },
  { label: '+60', value: 'Malaysia' },
  { label: '+65', value: 'Singapore' },
  { label: '+63', value: 'Philippines' },
  { label: '+62', value: 'Indonesia' },
  { label: '+41', value: 'Switzerland' },
  { label: '+31', value: 'Netherlands' },
  { label: '+32', value: 'Belgium' },
  { label: '+46', value: 'Sweden' },
  { label: '+47', value: 'Norway' },
  { label: '+45', value: 'Denmark' },
  { label: '+353', value: 'Ireland' },
  { label: '+92', value: 'Pakistan' },
  { label: '+971', value: 'United Arab Emirates' },
  { label: '+90', value: 'Turkey' },
  { label: '+20', value: 'Egypt' },
  { label: '+66', value: 'Thailand' },
  { label: '+48', value: 'Poland' },
  { label: '+351', value: 'Portugal' },
];
